import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    children:[
      {
        path:'',
        name:'Welcome',
        component:()=>import('../views/Welcome')
      },
      {
        path:'projectList',
        name:'ProjectList',
        component:()=>import('../views/ProjectList/ProjectList')
      },
      {
        path:'logCategory',
        name:'LogCategory',
        component:()=>import('../views/ProjectList/LogCategory')
      },
      {
        path:'projectLog',
        name:'ProjectLog',
        component:()=>import('../views/ProjectList/ProjectLog')
      },
      {
        path:'users',
        name:'Users',
        component:()=>import('../views/UserList/Users')
      },
      {
        path:'/role',
        name:'RolePermissions',
        component:()=>import('../views/UserList/RolePermissions')
      },
      {
        path:'/addNode',
        name:'addNode',
        component:()=>import('../views/addNode/addNode')
      },
      {
        path:'/addSubNode',
        name:'addSubNode',
        component:()=>import('../views/addNode/addSubNode')
      }
    ]
  },
  {
    path:'/login',
    name:'Login',
    component:()=>import('../views/Login')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: '/log/',
  routes
})

export default router
