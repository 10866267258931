export default [
    {
      mainTitle: "用户管理",
      icon: "el-icon-user-solid",
      active: "1",
      linkName: "",
      subMenu: [
        {subTitle:'角色权限',subActive: '1-1',linkName: 'role'},
        {subTitle:'账号列表',subActive: '1-2',linkName: 'users'},
      ]
    },
    {
      mainTitle: "项目管理",
      icon: "el-icon-document",
      active: "2",
      linkName: "",
      subMenu:[
        {subTitle:"项目列表",subActive:"1-1",linkName:"projectList"},
        {subTitle:"日志类型",subActive:"1-2",linkName:"logCategory"},
      ]
    },
  /*  {
      mainTitle: "添加节点",
      icon: "el-icon-document",
      active: "12",
      linkName: "",
      subMenu:[
        {subTitle:"添加节点",subActive:"12-1",linkName:"addNode"},
        {subTitle:"添加子节点",subActive:"12-2",linkName:"addSubNode"},
      ]
    },*/
  ]

