import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue'
import router from './router'
import store from './store'

import baseUrl from "./utils/baseUrl";
Vue.prototype.baseUrl = baseUrl;

import {request} from "./utils/request";
Vue.prototype.$request = request;

import {mainFun} from "./utils/mainFun";
Vue.prototype.mainFun = mainFun;

import timeTransfer from './utils/timeTransfer'
Vue.prototype.$time = timeTransfer

import './utils/flexible'

import setMenuActive from "./utils/setMenuActive";
Vue.prototype.$setMenuActive = setMenuActive

import {matchJurisdiction} from "./utils/matchJurisdiction";
Vue.prototype.matchJurisdiction = matchJurisdiction;

Vue.config.productionTip = false
Vue.use(ElementUI)

new Vue({
  router,
  store,
  render: h => h(App),
  beforeCreate() {
    Vue.prototype.$bus = this;
  }
}).$mount('#app')
