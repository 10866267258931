<template>
  <div class="leftMenu">
    <el-menu
        :default-active="menuActive"
        class="el-menu-vertical-demo"
        background-color="#545c64"
        text-color="#fff"
        unique-opened
        router
        active-text-color="#ffd04b">
      <el-submenu :index="item.active" v-for="(item,index) in menus" :key="index">
        <template slot="title">
          <span>{{item.mainTitle}}</span>
        </template>
        <el-menu-item-group>
          <el-menu-item :index="subItem.linkName" v-for="(subItem,subIndex) in item.subMenu" :key="subIndex">
            {{subItem.subTitle}}
          </el-menu-item>
        </el-menu-item-group>
      </el-submenu>
    </el-menu>
  </div>
</template>

<script>
import menu from "../utils/menu";
export default {
  name: "LeftMenus",
  data(){
    return{
      menus:[],
    }
  },
  computed:{
    menuActive(){
      return sessionStorage.getItem('dActive') || '/'
    },
  },
  mounted() {
    let userInfo = JSON.parse(sessionStorage.getItem('userInfo'))
    let userId = userInfo.user_id;
    this.user = userInfo.account;
    if(userId == 1){
      this.menus = menu;
    }else{
      this.getMenu()
    }
    let active = sessionStorage.getItem('dActive');
    if(active){
      this.dActive = active;
    }

  },
  methods:{
    //获取左边菜单导航
    async getMenu(){
      let res = await this.mainFun('AdminRole/node');
      // console.log(res)
      let data1 = res.data[0];   //页面
      let data2 = res.data[1];   //分组
      let keyArr = [],valArr = [];
      for(let k in data1){
        keyArr.push(k);
        valArr.push(data1[k])
      }
      let menu = [],nodeArr = [];
      for(let i in data2){
        let index = keyArr.indexOf(data2[i].id+'');
        let subMenu = [],subNode = [];
        if(index > -1){
          let child = valArr[index];
          for(let j in child){
            if(child[j].title != '日志列表'){
              let subActive = parseInt(i) + 1 + "_" + (parseInt(j) + 1);
              subMenu.push({subTitle:child[j].title,subActive,linkName:child[j].page});
            }
            subNode.push({subTitle:child[j].title,linkName:child[j].page,interface:child[j]._child})
          }
        }
        menu.push({mainTitle:data2[i].name,active:parseInt(i)+1+'',linkName:"",subMenu})
        nodeArr = nodeArr.concat(subNode)
      }
      this.menus = menu;
      sessionStorage.setItem('menu',JSON.stringify(nodeArr))
    },
  }
}
</script>
<style>
.el-submenu .el-menu-item{
  height:36px;
  line-height: 36px;
  padding:0;
  min-width: 140px;
  font-size:12px;
}
.el-menu-item, .el-submenu__title{
  height:44px;
  line-height: 44px;
}
.el-menu-item-group__title{
  padding:0 0 0 10px;
}
.el-menu{
  border-right:0;
}
.el-submenu__title{
  text-align: left;
}
.el-menu-item,.el-menu-item-group__title{
  display: flex;
  align-items: center;
  margin-top:10px;
}


</style>
<style scoped lang="less">
  .leftMenu{
    width:12vw;
    height:calc(100vh - 70px);
    background:#545c64;
    overflow: auto;
    border-top:1px solid #fff;
    box-sizing: border-box;
  }
</style>