let menu = sessionStorage.getItem('menu');
export function matchJurisdiction(name){
  if(menu){
    menu = eval(menu);
    let nameArr = [];
    for(let item of menu){
      for(let subItem of item.interface){
        nameArr.push(subItem.name)
      }
    }
    if(nameArr.indexOf(name) > -1){
      return true;
    }else{
      return false;
    }
  }else{
    return true;
  }
}
